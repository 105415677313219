import React, { FC } from 'react'
import { Row, Col, Spinner, Card } from 'react-bootstrap'
import { Provenance } from '../../../types/FHIRTypes/Provenance'
import { formatDateInTimeZone, handleCodeableConcept } from '../../../utils/helpers'

interface Props {
    provenanceEntries: Provenance[]
    isFetching: boolean
}

const ProvenanceDetail: FC<Props> = ({ provenanceEntries, isFetching }) => {

    const validProvenanceEntries = provenanceEntries.filter(entry => entry !== undefined)
    if (isFetching) {
        return (
            <div className='d-flex dataContainer'>
                <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                <span style={{ marginLeft: '10px' }}>Loading Provenance Data...</span>
            </div>
        )
    }

    if (validProvenanceEntries.length === 0) {
        return null
    }

    return (
        <dl className='dataContainer'>
            <h5>Provenance</h5>
            <Row>
                {validProvenanceEntries.map((provenance, index) => (
                    <Col sm={12} key={index}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Entry {index + 1}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={3}>
                                        <dt>Time Stamp</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{provenance?.recorded ? formatDateInTimeZone(provenance?.recorded, 'MM/dd/yyyy', 'UTC') : ''}</dd>
                                    </Col>
                                    <Col sm={3}>
                                        <dt>Target Resource(s)</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {provenance?.target && Array.isArray(provenance?.target) ? (
                                                provenance?.target.map((target: any, targetIndex: number) => (
                                                    <div key={`target-${targetIndex}`}>
                                                        {target?.reference}
                                                    </div>
                                                ))
                                            ) : ''}
                                        </dd>
                                    </Col>

                                    <Col sm={3}>
                                        <dt>Occured Period</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{provenance?.occurredPeriod ? provenance?.occurredPeriod : ''}</dd>
                                    </Col>

                                    <Col sm={3}>
                                        <dt>Occured Date</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{provenance?.occurredDateTime ? formatDateInTimeZone(provenance?.occurredDateTime, 'MM/dd/yyyy', 'UTC') : ''}</dd>
                                    </Col>

                                    <Col sm={3}>
                                        <dt>Policy</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {provenance?.policy ? (
                                                provenance?.policy.map((policyUri: string, index: number) => (
                                                    <div key={`policy-${index}`}>
                                                        {policyUri}
                                                    </div>
                                                ))
                                            ) : ''}
                                        </dd>
                                    </Col>

                                    <Col sm={3}>
                                        <dt>Location</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{provenance?.location ? provenance?.location?.reference : ''}</dd>
                                    </Col>

                                    <Col sm={3}>
                                        <dt>Authorization</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {Array.isArray(provenance?.authorization) ? (
                                                provenance?.authorization.map((auth: any, index: number) => (
                                                    <div key={`authorization-${index}`}>
                                                        {auth?.reference}
                                                    </div>
                                                ))
                                            ) : ''}
                                        </dd>
                                    </Col>

                                    <Col sm={3}>
                                        <dt>Activity</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{provenance?.activity?.coding?.length ? provenance?.activity?.coding.map((coding: any, i: any) => (
                                            <span key={i}>
                                                {handleCodeableConcept(coding)}
                                                {i < ((provenance?.activity?.coding?.length ?? 0) - 1) && ', '}
                                            </span>
                                        )) : ''}</dd>
                                    </Col>
                                    <Col sm={3}>
                                        <dt>Agents</dt>
                                    </Col>
                                    <Col sm={9}>
                                        {provenance?.agent?.length ? provenance?.agent.map((agent: any, agentIndex: any) => (
                                            <div key={`agent-${agentIndex}`} className='border rounded m-2 p-2'>
                                                <p>Type: {agent?.type ? handleCodeableConcept(agent.type) : ''}</p>
                                                <p>Role: {agent?.role?.length ? agent?.role.map((role: any, roleIndex: any) => (
                                                    <span key={roleIndex}>
                                                        {handleCodeableConcept(role)}
                                                        {roleIndex < (agent?.role?.length - 1) && ', '}
                                                    </span>
                                                )) : ''}</p>
                                                <p>Who: {agent?.who?.reference || ''}</p>
                                                <p>On Behalf Of: {agent?.onBehalfOf?.reference || ''}</p>
                                            </div>
                                        )) : (
                                            <div className='border rounded m-2 p-2'>
                                                <p>Type: </p>
                                                <p>Role: </p>
                                                <p>Who: </p>
                                                <p>On Behalf Of: </p>
                                            </div>
                                        )}
                                    </Col>
                                    <Col sm={3}>
                                        <dt>Entities</dt>
                                    </Col>
                                    <Col sm={9}>
                                        {provenance?.entity?.length ? provenance?.entity.map((entity: any, entityIndex: any) => (
                                            <div key={`entity-${entityIndex}`} className='border rounded m-2 p-2'>
                                                <p>Role: {entity.role || ''}</p>
                                                <p>What: {entity.what?.reference || ''}</p>
                                            </div>
                                        )) : (
                                            <div className='border rounded m-2 p-2'>
                                                <p>Role: </p>
                                                <p>What: </p>
                                            </div>
                                        )}
                                    </Col>
                                    <Col sm={3}>
                                        <dt>Signatures</dt>
                                    </Col>
                                    <Col sm={9}>
                                        {provenance?.signature?.length ? provenance?.signature.map((signature: any, sigIndex: any) => (
                                            <div key={`signature-${sigIndex}`} className='border rounded m-2 p-2'>
                                                <p>Type: {signature?.type?.length ? signature?.type.map((type: any, typeIndex: any) => (
                                                    <span key={typeIndex}>
                                                        {handleCodeableConcept(type)}
                                                        {typeIndex < (signature?.type.length - 1) && ', '}
                                                    </span>
                                                )) : ''}</p>
                                                <p>When: {signature?.when ? formatDateInTimeZone(signature?.when, 'MM/dd/yyyy', 'UTC') : ''}</p>
                                                <p>Who: {signature?.who?.reference || ''}</p>
                                                <p>On Behalf Of: {signature?.onBehalfOf?.reference || ''}</p>
                                                <p>Target Format: {signature?.targetFormat || ''}</p>
                                                <p>Sig Format: {signature?.sigFormat || ''}</p>
                                                <p>Data: {signature?.data || ''}</p>
                                            </div>
                                        )) : (
                                            <div className='border rounded m-2 p-2'>
                                                <p>Type: </p>
                                                <p>When: </p>
                                                <p>Who: </p>
                                                <p>On Behalf Of: </p>
                                                <p>Target Format: </p>
                                                <p>Sig Format: </p>
                                                <p>Data: </p>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </dl>
    )
}

export default ProvenanceDetail