import React from 'react'
import { Button, Form, Spinner, Table } from 'react-bootstrap'
import { ProviderResource } from '../../../../types/ProviderResource'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../PaginationButtons'
import useEnvironment from '../../../../hooks/location/useEnviroment'

interface PractitionerListProps {
    practitioners: any
    clickViewDataLink: (organization: ProviderResource) => void
    page: number
    isFetching: boolean
    handleNextPage: () => void
    handlePrevPage: () => void
    isLoading: boolean
    isSuccess: boolean
    searchProviderResource: { searchTerms: string, resource: string }
    clearForm: () => void
    newSearch: () => void
    updateSearchTerms: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const PractitionerList: React.FC<PractitionerListProps> = ({ practitioners, clickViewDataLink, page, isFetching, handleNextPage, handlePrevPage, isLoading, isSuccess, searchProviderResource, clearForm, newSearch, updateSearchTerms }) => {
    const { data: environment } = useEnvironment()

    const handleNewSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        newSearch()
    }

    console.log('practitioners', practitioners)

    return (
        <>
            <h3>Practitioners</h3>
            <Form noValidate className='form-inline' onSubmit={handleNewSearch}>
                <Form.Group>
                    <Form.Label style={{ margin: '0 1em 0 0' }}>Search by Practitioner Name</Form.Label>
                    <Form.Control
                        style={{ margin: '0 1em 0 0' }}
                        type='text'
                        name='keyword'
                        placeholder="Enter Search Terms"
                        value={searchProviderResource.searchTerms}
                        onChange={updateSearchTerms}
                    />
                </Form.Group>
                <Button className='searchBtn mr-2' type='submit'>
                    Search
                </Button>
                <Button className='outline-searchBtn' onClick={clearForm}>
                    Clear
                </Button>
            </Form>
            <hr />
            <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                {
                    practitioners && practitioners?.data.length > 0 && (
                        <div className='mb-3'>
                            <PaginationButtons
                                backDisabled={page === 0 || !practitioners.previousLink || isFetching}
                                nextDisabled={!practitioners.nextLink || isFetching}
                                onBackClick={handlePrevPage}
                                onNextClick={handleNextPage}
                            />
                        </div>
                    )
                }
                {
                    environment?.includeTotalInFHIRRequest && (
                        isLoading || isFetching
                            ? <Spinner animation="border" size="sm" />
                            : practitioners && `${practitioners.total.toLocaleString()} record(s) returned`
                    )
                }
            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='name-column'>Name</th>
                        <th className='address-column'>Address</th>
                        <th className='detail-column'>View Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading || isFetching ? (
                            <tr>
                                <td colSpan={3}>Loading...</td>
                            </tr>
                        ) : practitioners && isSuccess ?
                            practitioners.data.sort((a: ProviderResource, b: ProviderResource) => {
                                if (!a.name) return -1
                                if (!b.name) return 1
                                return a.name.localeCompare(b.name)
                            }).map((practitioner: ProviderResource) => (
                                <tr key={practitioner.id}>
                                    <td>{practitioner.name}</td>
                                    <td>{practitioner.address}</td>
                                    <td>
                                        <Link to='#' onClick={() => clickViewDataLink(practitioner)}>View Detail</Link>
                                    </td>
                                </tr>
                            )) : null
                    }
                </tbody>
                <tfoot>
                    {
                        practitioners && practitioners?.data.length > 0 && (
                            <tr>
                                <td scope="row" colSpan={3}>
                                    <PaginationButtons
                                        backDisabled={page === 0 || !practitioners.previousLink || isFetching}
                                        nextDisabled={!practitioners.nextLink || isFetching}
                                        onBackClick={handlePrevPage}
                                        onNextClick={handleNextPage}
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tfoot>
            </Table>
        </>
    )
}

export default PractitionerList