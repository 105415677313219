import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatObservationsForTable } from '../../../../utils/fhirUtils/observation'
import { buildPatientId } from '../../../../utils/fhirUtils/patient'
import { formatDateInTimeZone, handleCodeableConcept } from '../../../../utils/helpers'
import { ObservationBundle } from '../../../../types/FHIRTypes/Bundle'
import { Observation } from '../../../../types/FHIRTypes/Observation'

interface Props {
  observationData: ObservationBundle;
  isFetching: boolean;
}

const ObservationList: FC<Props> = ({ observationData, isFetching }: Props) => {
  const observations = observationData && observationData.entry ? observationData.entry.map((entry) => entry.resource) : []

  const handleObservationEffectiveDate = (observation: Observation) => {
    if (observation.effectiveDateTime) {
      return new Date(observation.effectiveDateTime).toLocaleDateString()
    }

    if (observation.effectivePeriod) {
      const startDate = observation.effectivePeriod.start || ''
      const endDate = observation.effectivePeriod.end || ''
      return endDate
        ? `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`
        : new Date(startDate).toLocaleDateString()
    }

    return ''
  }

  return (
    <>
    {observationData?.total > 0 && (
      <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
          {`${observationData.total.toLocaleString()} record(s) found`}
      </div>
    )}
    <Table size='sm' hover>
      <thead>
        <tr>
          <th className='d-table-cell'>Code</th>
          <th className='d-none d-lg-table-cell'>Status</th>
          <th className='d-none d-lg-table-cell'>Effective Date</th>
          <th className='d-none d-lg-table-cell'>View Detail</th>
        </tr>
      </thead>
      <tbody>
        {
          isFetching ? (
            <tr>
              <td colSpan={4} className='d-flex'>
                <Spinner
                  as='span'
                  animation='border'
                  role='status'
                  aria-hidden='true'
                />
                <span style={{ marginLeft: '10px' }}>Loading Member Observations...</span>
              </td>
            </tr>
          ) : observations.length === 0 ? (
            <tr>
              <td colSpan={4} rowSpan={15} className='text-center'>
                No Observations found for this member.
              </td>
            </tr>
          ) : (
            <>
              {
                observations.map((observation: Observation) => (
                  <tr key={observation.id}>
                    <td className='d-table-cell'><Link to={`/patient/Observation/${observation.id}`}>{observation.code && handleCodeableConcept(observation.code)}</Link></td>
                    <td className='d-none d-lg-table-cell'>{observation.status}</td>
                    <td className='d-none d-lg-table-cell'>{handleObservationEffectiveDate(observation)}</td>
                    <td className='d-none d-lg-table-cell'><Link to={`/patient/Observation/${observation.id}`}>View Detail</Link></td>
                  </tr>
                ))
              }
            </>
          )
        }
      </tbody>

    </Table>
    </>
  )
}

export default ObservationList