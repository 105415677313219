import React, { FC } from 'react'
import ObservationDetail from './ObservationDetail'
import ObservationList from './ObservationList'
import { ObservationBundle } from '../../../types/FHIRTypes/Bundle'
import { buildPatientName } from '../../../utils/fhirUtils/patient'

interface Props {
  observationData: ObservationBundle;
  patientData: any;
  observationDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
}

const Observations: FC<Props> = ({ observationData, patientData, observationDetailId, page, isFetching }: Props) => {

  return (
    <div>
      <h3>Observations</h3>
      <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>


      {
        observationDetailId && page === 'Observation' ? (
          <ObservationDetail observationData={observationData} patientData={patientData} detailId={observationDetailId} />
        ) : <ObservationList observationData={observationData} isFetching={isFetching} />
      }
    </div>
  )
}

export default Observations