import React, { FC } from 'react'
import ConditionList from './ConditionsList'
import ConditionDetail from './ConditionsDetail'
import { buildPatientName } from '../../../utils/fhirUtils/patient'

interface Props {
    conditionData: any;
    patientData: any;
    conditionDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
}



const Conditions: FC<Props> = ({ conditionData, patientData, conditionDetailId, page, isFetching }: Props) => {
    return (
        <div>
            <h3>Conditions</h3>
            <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
            <>
            {conditionData?.total > 0 && (
            <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                {`${conditionData.total.toLocaleString()} record(s) found`}
            </div>
            )}
            {
                conditionDetailId && page === 'Condition' ? (
                    <ConditionDetail conditionData={conditionData} isFetching={isFetching} conditionDetailId={conditionDetailId} />
                ) : <ConditionList conditionData={conditionData} patientData={patientData} isFetching={isFetching} />
            }
            </>
        </div>
    )
}

export default Conditions