import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { buildPatientId } from '../../../../utils/fhirUtils/patient'
import { formatDateInTimeZone } from '../../../../utils/helpers'
import { sortConditionsByDisplay } from '../../../../utils/fhirUtils/sortConditions'
import { handleCodeableConcept } from '../../../../utils/helpers'

interface Props {
    conditionData: any;
    patientData: any;
    isFetching: boolean;
}


const ConditionList: FC<Props> = ({ conditionData, patientData, isFetching }: Props) => {
    const patientConditions = conditionData && conditionData.entry ? conditionData.entry.map((condition: any) => condition.resource) : []


    const getConditionRecordedDate = (item: any) => {
        return item.recordedDate ? formatDateInTimeZone(item.recordedDate, 'MM/dd/yyyy', 'UTC') : ''
    }




    // console.dir(patientConditions)
    return (
        <Table size='sm' hover>
            <thead>
                <tr>
                    <th className='d-table-cell'>Code</th>
                    <th className='d-none d-lg-table-cell'>Category</th>
                    <th className='d-none d-lg-table-cell'>Recorded Date</th>
                    <th className='d-none d-lg-table-cell'>View Detail</th>
                </tr>
            </thead>
            <tbody>
                {
                    isFetching ? (
                        <tr>
                            <td colSpan={6} rowSpan={15} className='d-flex'>
                                <Spinner
                                    as='span'
                                    animation='border'
                                    role='status'
                                    aria-hidden='true'
                                />
                                <span style={{ marginLeft: '10px' }}>Loading Member Diagnoses...</span>
                            </td>
                        </tr>
                    ) : patientConditions?.length === 0 ? (
                        <tr>
                            <td colSpan={6} rowSpan={15} className='text-center'>
                                No conditions found for this member.
                            </td>
                        </tr>
                    ) : (
                        <>
                            {patientConditions.map((condition: any) => (
                                <tr key={condition.id}>
                                    <td className='d-table-cell'><Link to={`/patient/Condition/${condition.id}`}>{handleCodeableConcept(condition?.code)}</Link></td>
                                    <td className='d-none d-lg-table-cell'>{handleCodeableConcept(condition?.category[0])}</td>
                                    <td className='d-none d-lg-table-cell'>{getConditionRecordedDate(condition)}</td>
                                    <td className='d-none d-lg-table-cell'><Link to={`/patient/Condition/${condition.id}`}>View Detail</Link></td>
                                </tr>
                            ))}

                        </>

                    )
                }
            </tbody>
        </Table>
    )
}


export default ConditionList