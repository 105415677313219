import React, { FC } from 'react'
import { Condition } from '../../../../types/FHIRTypes/Condition'
import { Col } from 'react-bootstrap'
import { buildHumanName, formatDateWithFormat, handleCodeableConcept } from '../../../../utils/helpers'

interface ConditionDetailProps {
    condition?: Condition;
    subject: any;
}

const ConditionDetail: FC<ConditionDetailProps> = ({ condition, subject }) => {
    return (
        <>
            <Col sm={3}>
                <dt>ID</dt>
            </Col>
            <Col sm={9}>
                {condition?.id || ''}
            </Col>
            <Col sm={3}>
                <dt>Last Updated</dt>
            </Col>
            <Col sm={9}>
                {condition?.meta?.lastUpdated ? formatDateWithFormat(condition.meta.lastUpdated, 'MM/dd/yyyy HH:mm:ss') : ''}
            </Col>
            <Col sm={3}>
                <dt>Language</dt>
            </Col>
            <Col sm={9}>
                {condition?.language || ''}
            </Col>
            <Col sm={3}>
                <dt>Clinical Status</dt>
            </Col>
            <Col sm={9}>
                {condition?.clinicalStatus ? handleCodeableConcept(condition.clinicalStatus) : ''}
            </Col>
            <Col sm={3}>
                <dt>Verification Status</dt>
            </Col>
            <Col sm={9}>
                {condition?.verificationStatus ? handleCodeableConcept(condition.verificationStatus) : ''}
            </Col>
            <Col sm={3}>
                <dt>Category</dt>
            </Col>
            <Col sm={9}>
                <dd>
                    {
                        condition?.category?.map((category) => handleCodeableConcept(category)).join(', ')
                    }
                </dd>
            </Col>
            <Col sm={3}>
                <dt>Code</dt>
            </Col>
            <Col sm={9}>
                {condition?.code ? handleCodeableConcept(condition.code) : ''}
            </Col>
            <Col sm={3}>
                <dt>Subject</dt>
            </Col>
            <Col sm={9}>
                {subject ? buildHumanName(subject.name) : ''}
            </Col>
        </>
    )
}

export default ConditionDetail