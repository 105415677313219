import React from 'react'
import { buildPatientName } from '../../../utils/fhirUtils/patient'
import { EncounterBundle } from '../../../types/FHIRTypes/Bundle'
import EncounterList from './EncounterList'
import { ConditionEntry, EncounterEntry, LocationEntry, PractitionerEntry } from '../../../types/FHIRTypes/Entry'
import EncounterDetail from './EncounterDetail'

interface EncountersProps {
    patientData: any;
    page: string | undefined;
    isFetching: boolean;
    encounterDetailId: string | undefined;
    encounterData: EncounterBundle | undefined;
    handleChangePage: (url: string) => void;
}

const Encounters: React.FC<EncountersProps> = ({ patientData, encounterDetailId, page, encounterData, isFetching, handleChangePage }) => {
    const encounters = (encounterData?.entry?.filter((entry) => entry.resource.resourceType === 'Encounter') || []) as EncounterEntry[]
    const conditions = (encounterData?.entry?.filter((entry) => entry.resource.resourceType === 'Condition') || []) as ConditionEntry[]
    const practitioners = (encounterData?.entry?.filter((entry) => entry.resource.resourceType === 'Practitioner') || []) as PractitionerEntry[]
    const locations = (encounterData?.entry?.filter((entry) => entry.resource.resourceType === 'Location') || []) as LocationEntry[]

    return (
        <>
            <h3>Encounters</h3>
            <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
            {
                encounterDetailId && page === 'Encounter' ? (
                    <EncounterDetail
                        encounters={encounters}
                        encounterDetailId={encounterDetailId}
                        conditions={conditions}
                        practitioners={practitioners}
                        locations={locations}
                        isFetching={isFetching}
                        dataExists={encounterData !== undefined}
                        patientData={patientData}
                    />
                ) :
                    <EncounterList
                        data={encounterData}
                        encounters={encounters}
                        isFetching={isFetching}
                        handleChangePage={handleChangePage}
                    />
            }
        </>
    )
}

export default Encounters