import React from 'react'
import { ProviderResource } from '../../../../types/ProviderResource'
import { Button, Form, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../PaginationButtons'
import useEnvironment from '../../../../hooks/location/useEnviroment'

interface OrganizationListProps {
    organizations: any
    clickViewDataLink: (organization: ProviderResource) => void
    page: number
    isFetching: boolean
    handleNextPage: () => void
    handlePrevPage: () => void
    isLoading: boolean
    isSuccess: boolean,
    searchProviderResource: { searchTerms: string, resource: string }
    clearForm: () => void
    newSearch: () => void
    updateSearchTerms: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const OrganizationList: React.FC<OrganizationListProps> = ({ organizations, clickViewDataLink, page, isFetching, handleNextPage, handlePrevPage, isLoading, isSuccess, searchProviderResource, clearForm, newSearch, updateSearchTerms }) => {

    const handleNewSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        newSearch()
    }

    const { data: environment } = useEnvironment()

    return (
        <>
            <h3>Organizations</h3>
            <Form noValidate className='form-inline' onSubmit={handleNewSearch}>
                <Form.Group>
                    <Form.Label style={{ margin: '0 1em 0 0' }}>Search by Organization Name</Form.Label>
                    <Form.Control
                        style={{ margin: '0 1em 0 0' }}
                        type='text'
                        name='keyword'
                        placeholder="Enter Search Terms"
                        value={searchProviderResource.searchTerms}
                        onChange={updateSearchTerms}
                    />
                </Form.Group>
                <Button className='searchBtn mr-2' type='submit'>
                    Search
                </Button>
                <Button className='outline-searchBtn' onClick={clearForm}>
                    Clear
                </Button>
            </Form>
            <hr />
            <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                {
                    organizations?.data.length > 0 && (
                        <div className='mb-3'>
                            <PaginationButtons
                                backDisabled={page === 0 || !organizations.previousLink || isFetching}
                                nextDisabled={!organizations.nextLink || isFetching}
                                onBackClick={handlePrevPage}
                                onNextClick={handleNextPage}
                            />
                        </div>
                    )
                }
                {environment?.includeTotalInFHIRRequest && (
                    <span className='d-md-block'>
                        {(isLoading || isFetching)
                            ? <Spinner animation="border" size="sm" />
                            : organizations && `${organizations.total.toLocaleString()} record(s) returned`
                        }
                    </span>
                )}
            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='name-column'>Name</th>
                        <th className='address-column'>Address</th>
                        <th className='detail-column'>View Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading || isFetching ? (
                            <tr>
                                <td colSpan={3}>Loading...</td>
                            </tr>
                        ) : organizations && isSuccess ?
                            organizations.data.map((organization: ProviderResource) => (
                                <tr key={organization.id}>
                                    <td>{organization.name}</td>
                                    <td>{organization.address}</td>
                                    <td>
                                        <Link
                                            to='#'
                                            onClick={() => clickViewDataLink(organization)}
                                        >
                                            View Detail
                                        </Link>
                                    </td>
                                </tr>
                            )) : null
                    }
                </tbody>
                <tfoot>
                    {
                        organizations && organizations?.data.length > 0 && (
                            <tr>
                                <td scope="row" colSpan={3}>
                                    <PaginationButtons
                                        backDisabled={page === 0 || !organizations.previousLink || isFetching}
                                        nextDisabled={!organizations.nextLink || isFetching}
                                        onBackClick={handlePrevPage}
                                        onNextClick={handleNextPage}
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tfoot>
            </Table>
        </>
    )
}

export default OrganizationList