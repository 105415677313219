import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { handleCodeableConcept } from '../../../../utils/helpers'

interface MedicationsProps {
    medicationData: any;
    isFetching: boolean;
}

const MedicationList: React.FC<MedicationsProps> = ({ medicationData, isFetching }) => {
    const requestedMedications = medicationData && medicationData.entry ? medicationData.entry.map((medication: any) => medication.resource) : []

    return (
        <>
        {medicationData?.total > 0 && (
          <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
            {`${medicationData.total.toLocaleString()} record(s) found`}
          </div>
        )}
        <Table size='sm' hover>
            <thead>
                <tr>
                    <th className='d-table-cell'>Medication</th>
                    <th className='d-none d-lg-table-cell'>View Detail</th>
                </tr>
            </thead>
            <tbody>
                {
                    isFetching ? (
                        <tr>
                            <td colSpan={4} rowSpan={15} className='d-flex'>
                                Loading Member Medications...
                            </td>
                        </tr>
                    ) : requestedMedications?.length === 0 ? (
                        <tr>
                            <td colSpan={4} rowSpan={15} className='text-center'>
                                No Medications found for this member.
                            </td>
                        </tr>
                    ) : (
                        <>
                            {requestedMedications.map((medication: any) => (
                                <tr key={medication.id}>
                                    <td className='d-table-cell'><Link to={`/patient/MedicationRequest/${medication.id}`}>{medication.medicationCodeableConcept ? handleCodeableConcept(medication.medicationCodeableConcept) : medication.medicationReference?.display ?? ''}</Link></td>
                                    <td className='d-none d-lg-table-cell'><Link to={`/patient/MedicationRequest/${medication.id}`}>View Detail</Link></td>
                                </tr>
                            ))}
                        </>
                    )
                }
            </tbody>
        </Table>
        </>
    )
}

export default MedicationList