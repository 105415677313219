import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { handleCodeableConcept } from '../../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import { MedicationRequest } from '../../../../types/FHIRTypes/MedicationRequest'
import { buildPatientName } from '../../../../utils/fhirUtils/patient'

interface MedicationDetailProps {
    medicationDetailId: any;
    medicationData: any;
    isFetching: boolean;
    patientData: any
}

const MedicationDetail: React.FC<MedicationDetailProps> = ({ medicationDetailId, medicationData, isFetching, patientData }) => {
    const [medication, setMedication] = useState<MedicationRequest>({} as MedicationRequest)

    const history = useHistory()
    useEffect(() => {
        if (!medicationData) return

        const foundMedication = medicationData.entry.find((a: any) => a.resource.id === medicationDetailId)
        if (foundMedication) {
            setMedication(foundMedication.resource)
        } else {
            history.push('/404')
        }
    }, [medicationData])

    if (isFetching) {
        return (
            <div>
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
                Loading Medication...
            </div>
        )
    }

    const showStatusReason = (medication: MedicationRequest) => {
        if (!medication.statusReason) return ''

        return handleCodeableConcept(medication?.statusReason[0])
    }

    const showMedication = (medication: MedicationRequest) => {
        if (!medication.medicationCodeableConcept && !medication.medicationReference) return ''

        if (medication.medicationCodeableConcept) return handleCodeableConcept(medication.medicationCodeableConcept)

        return medication.medicationReference?.display ?? ''
    }

    return (
        <dl className='dataContainer'>
            {
                medication && (
                    <>
                        <Row>
                            <Col sm={3}>
                                <dt>Id</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.id}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Medication</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{showMedication(medication)}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Subject</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{patientData ? buildPatientName(patientData.name) : ''}</dd>
                            </Col>
                            <Col sm={3} className='font-weight-bold'>
                                Dispense Request:
                            </Col>
                            <Col sm={9} className='border p-3 rounded'>
                                <Row>
                                    <Col xs={3}>
                                        Initial Fill Quantity:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.dispenseRequest?.initialFill?.quantity?.value} {medication?.dispenseRequest?.initialFill?.quantity?.unit}
                                    </Col>
                                    <Col xs={3}>
                                        Initial Fill Duration:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.dispenseRequest?.initialFill?.duration}
                                    </Col>
                                    <Col xs={3}>
                                        Dispense Interval:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.dispenseRequest?.dispenseInterval}
                                    </Col>
                                    <Col xs={3}>
                                        Validity Period:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.dispenseRequest?.validityPeriod?.start} {medication?.dispenseRequest?.validityPeriod?.end}
                                    </Col>
                                    <Col xs={3}>
                                        Number Of Repeats Allowed:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.dispenseRequest?.numberOfRepeatsAllowed}
                                    </Col>
                                    <Col xs={3}>
                                        Quantity:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.dispenseRequest?.quantity?.value} {medication?.dispenseRequest?.quantity?.unit}
                                    </Col>
                                    <Col xs={3}>
                                        Expected Supply Duration:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.dispenseRequest?.expectedSupplyDuration}
                                    </Col>
                                    <Col xs={3}>
                                        Performer:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.dispenseRequest?.performer?.reference}
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <dt>Intent</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.intent}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Priorty</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.priority}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Status</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.status}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Status Reason</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{showStatusReason(medication)}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Supporting Information</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.supportingInformation?.map((part) => part.reference).join(', ')}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Performer</dt>
                            </Col>
                            <Col sm={9}>
                                {medication?.performer?.display ?? ''}
                            </Col>
                            <Col sm={3}>
                                <dt>Category</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.category ? handleCodeableConcept(medication.category[0]) : ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Encounter</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.encounter?.display ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Authored On</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.authoredOn ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Requester</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.requester?.display ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Performer</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.performer?.display ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Performer Type</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.performerType ? handleCodeableConcept(medication?.performerType) : ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Recorder</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.recorder?.reference ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Reason Code</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.reasonCode?.map((code) => handleCodeableConcept(code)).join(', ')}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Reason Reference</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.reasonReference?.map((reason) => reason?.reference ?? '').join(', ')}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Based On</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.basedOn?.map((base) => base?.reference ?? '').join(', ')}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Course Of Therapy Type</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.courseOfTherapyType ? handleCodeableConcept(medication?.courseOfTherapyType) : ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Insurance</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication?.insurance?.map((insurance) => insurance?.reference ?? '').join(', ')}</dd>
                            </Col>
                            <Col sm={3} className='font-weight-bold'>
                                Substitution:
                            </Col>
                            <Col sm={9} className='border rounded p-3'>
                                <Row>
                                    <Col xs={3}>
                                        Was Substituted:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.substitution?.allowedBoolean ? 'Yes' : 'No'}
                                    </Col>
                                    <Col xs={3}>
                                        Reason:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.substitution?.reason ? handleCodeableConcept(medication?.substitution?.reason) : ''}
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={3} className='my-3'>
                                <dt>Notes</dt>
                            </Col>
                            <Col sm={9}>
                                {
                                    medication.note?.map((note, index) => (
                                        <dd key={`note-${index}`}>{note.authorReference}: {note.text}</dd>
                                    ))
                                }
                            </Col>
                            <Col sm={3}>
                                <dt>Dosage Instructions</dt>
                            </Col>
                            <Col sm={9}>
                                {
                                    medication?.dosageInstruction?.map((dosage) => (
                                        <Card key={`dosage-instructions-${dosage.sequence}`}>
                                            <Card.Header>{dosage?.text}</Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12} className='font-weight-bold'>
                                                        Timing
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col xs={3}>
                                                                Frequency:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat.frequency}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Frequency Max:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat.frequencyMax}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Count:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.count}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Count Max:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.countMax}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Duration:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.duration}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Duration Max:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.durationMax}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Duration Unit:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.durationUnit}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Period:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.period}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Period Max:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.periodMax}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Period Unit:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.periodUnit}
                                                            </Col>
                                                            <Col xs={3}>
                                                                Time Of Day:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.timeOfDay?.map((day: any) => day).join(', ')}
                                                            </Col>
                                                            <Col xs={3}>
                                                                When:
                                                            </Col>
                                                            <Col xs={9}>
                                                                {dosage?.timing?.repeat?.when?.map((day: any) => day.code).join(', ')}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className='mt-3'>
                                                    <Col xs={6}>
                                                        <Row>
                                                            <Col>
                                                                As Needed:
                                                            </Col>
                                                            <Col>
                                                                {dosage?.asNeededBoolean ? 'Yes' : 'No'}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Row>
                                                            <Col>
                                                                Site:
                                                            </Col>
                                                            <Col>
                                                                {dosage.site ? handleCodeableConcept(dosage?.site) : ''}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Row>
                                                            <Col>
                                                                Route:
                                                            </Col>
                                                            <Col>
                                                                {dosage.route ? handleCodeableConcept(dosage?.route) : ''}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col>
                                                                Method:
                                                            </Col>
                                                            <Col>
                                                                {dosage.method ? handleCodeableConcept(dosage?.method) : ''}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} className='font-weight-bold'>
                                                        Dose and Rate:
                                                    </Col>
                                                    <Col xs={12}>
                                                        {
                                                            dosage?.doseAndRate?.map((doseAndRate, index: number) => (
                                                                <Row key={`dose-and-rate-${index}`}>
                                                                    <Col xs={6}>
                                                                        Type:
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        {doseAndRate.type ? handleCodeableConcept(doseAndRate.type) : ''}
                                                                    </Col>
                                                                    {
                                                                        doseAndRate.doseRange && (
                                                                            <>
                                                                                <Col xs={3}>
                                                                                    Dose Range Low:
                                                                                </Col>
                                                                                <Col xs={9}>
                                                                                    {doseAndRate.doseRange.low.value}
                                                                                </Col>
                                                                                <Col xs={3}>
                                                                                    Dose Range High:
                                                                                </Col>
                                                                                <Col xs={9}>
                                                                                    {doseAndRate.doseRange.high.value}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        doseAndRate?.doseQuantity && (
                                                                            <>
                                                                                <Col xs={3}>
                                                                                    Dose Quantity:
                                                                                </Col>
                                                                                <Col xs={9}>
                                                                                    {doseAndRate?.doseQuantity.value} {doseAndRate?.doseQuantity.unit}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        doseAndRate.rateRatio && (
                                                                            <>
                                                                                <Col xs={6}>
                                                                                    Rate Ratio Numerator:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate.rateRatio.numerator.value}
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    Rate Ratio Denominator:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate.rateRatio.denominator.value}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        doseAndRate?.rateRange && (
                                                                            <>
                                                                                <Col xs={6}>
                                                                                    Rate Range Low:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate?.rateRange.low.value} {doseAndRate?.rateRange.low.unit}
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    Rate Range High:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate?.rateRange.high.value} {doseAndRate?.rateRange.high.unit}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        doseAndRate?.rateQuantity && (
                                                                            <>
                                                                                <Col xs={6}>
                                                                                    Rate Quantity:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate?.rateQuantity.value}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                </Row>
                                                            ))
                                                        }
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    ))
                                }
                            </Col>
                        </Row>
                    </>
                )
            }
        </dl>
    )
}

export default MedicationDetail
