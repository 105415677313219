import React from 'react'
import MedicationList from './MedicationList'
import MedicationDetail from './MedicationDetail'
import { buildPatientName } from '../../../utils/fhirUtils/patient'

interface MedicationsProps {
    patientData: any;
    medicationData: any;
    medicationDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
}

const Medications: React.FC<MedicationsProps> = ({ patientData, medicationData, medicationDetailId, page, isFetching }) => {
    return (
        <>
            <h3>Medications</h3>
            <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>

            {
                medicationDetailId && page === 'MedicationRequest' ? (
                    <MedicationDetail medicationDetailId={medicationDetailId} medicationData={medicationData} isFetching={isFetching} patientData={patientData} />
                ) : <MedicationList medicationData={medicationData} isFetching={isFetching} />
            }
        </>
    )
}

export default Medications