import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { formatDollar } from '../../../../utils/helpers'
import { AdjudicationCodeEnum } from '../../../../types/AdjudicationCodeEnum'
import humanizeString from 'humanize-string'
import { appConfig } from '../../../../assets/customizations/config'
import { Adjudication, ExplanationOfBenefit } from '../../../../types/FHIRTypes/ExplanationOfBenefit'

interface Props {
    patientEob: ExplanationOfBenefit;
}

const EobItem: FC<Props> = ({ patientEob }) => {
    const items = patientEob.item
    const total = patientEob.total
    const procedure = patientEob?.procedure

    const getProcedureCode = (item: any) => {
        const proc = procedure?.find((p: any) => p.sequence === item.sequence)
        return proc?.procedureCodeableConcept?.coding ? proc.procedureCodeableConcept.coding[0].code : 'N/A'
    }

    const getProcedureDescription = (item: any) => {
        const proc = procedure?.find((p: any) => p.sequence === item.sequence)
        return proc && proc.procedureCodeableConcept ? proc.procedureCodeableConcept.text : 'N/A'
    }

    const sortLineItems = (lineItems: Adjudication[]) => {
        console.log(lineItems)
        return lineItems.sort((a, b) => {
            const aCode = a.category?.coding?.[0]?.code
            const bCode = b.category?.coding?.[0]?.code
        
            return aCode && bCode ?
                aCode > bCode ? 1 : bCode > aCode ? -1 : 0
                : aCode ? 1 : bCode ? -1 : 0
        })
    }

    const displayLineItemLabel = (code: string) => {
        const foundCode = AdjudicationCodeEnum[code as keyof typeof AdjudicationCodeEnum]
        return foundCode || humanizeString(code)
    }


    return (
        <>
            {
                items?.map((item: any, index) => (
                    <Card key={`eob-item-${index}-${item.sequence}`} className='mt-3 w-100'>
                        <Card.Header>
                            <div>
                                <p>Procedure Code: {getProcedureCode(item)}</p>
                            </div>
                            <div>
                                <p>Procedure Description: {getProcedureDescription(item)}</p>
                            </div>
                            <div>
                                {item.revenue && item.revenue.coding[0]?.code != undefined ? (
                                    <>
                                        Revenue Code: {item.revenue.coding[0].code}
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </Card.Header>
                        <Card.Body>
                            Adjudications:
                            <Row>
                                {
                                    sortLineItems(item.adjudication).map((adjudication, index) => (
                                        <Col key={`eob-item-adjudication-${index}-${adjudication.category.coding?.[0].code}`} xs={6}>
                                            <Row>
                                                <Col>
                                                    {displayLineItemLabel(adjudication.category.coding?.[0].code ?? '')}:                                                
                                                </Col>
                                                <Col className='text-right'>
                                                    {formatDollar(adjudication.amount?.value ?? 0)}
                                                </Col>
                                            </Row>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                ))
            }
            {appConfig.use_eob_total &&
                < Card className='mt-3'>
                    <Card.Header>
                        Total
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {
                                total && sortLineItems(total).map((t: any) => (
                                    <Col key={`total-item-${t.category.coding[0].code}`} xs={6}>
                                        <Row>
                                            <Col>
                                                {displayLineItemLabel(t.category.coding[0].code)}:
                                            </Col>
                                            <Col className='text-right'>
                                                {formatDollar(t.amount.value)}
                                            </Col>
                                        </Row>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Card.Body>
                </Card >
            }
        </>
    )
}

export default EobItem