import React, { FC } from 'react'
import CarePlanList from './CarePlanList'
import CarePlanDetail from './CarePlanDetail'
import { CarePlanBundle } from '../../../types/FHIRTypes/Bundle'
import { buildPatientName } from '../../../utils/fhirUtils/patient'

interface Props {
  patientData: any;
  carePlanData: CarePlanBundle;
  carePlanDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const CarePlans: FC<Props> = ({ patientData, carePlanData, carePlanDetailId, page, isFetching, handleChangePage }) => {
  return (
    <div>
      <h3>Care Plans</h3>
      <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
      {
        carePlanDetailId && page === 'CarePlan'
          ? (
            <CarePlanDetail
              carePlanDetailId={carePlanDetailId}
              carePlanData={carePlanData}
              isFetching={isFetching}
            />
          )
          : (
            <CarePlanList
              carePlanData={carePlanData}
              handleChangePage={handleChangePage}
              isFetching={isFetching}
            />
          )
      }
    </div>
  )
}
export default CarePlans