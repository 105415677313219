import React, { FC, useState, useEffect } from 'react'
import { appConfig } from '../../../assets/customizations/config'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

import Eobs from '../../../components/FhirData/Eobs'
import Immunizations from '../../../components/FhirData/Immunizations'
import MemberProfile from '../../../components/FhirData/MemberProfile'
import Observations from '../../../components/FhirData/Observations'
import Coverage from '../../../components/FhirData/Coverage'
import Conditions from '../../../components/FhirData/Conditions'
import Allergies from '../../../components/FhirData/Allergies'
import Medications from '../../../components/FhirData/Medications'
import Procedure from '../../../components/FhirData/Procedure'
import CarePlans from '../../../components/FhirData/CarePlans'
import isAuthenticated from '../../../routes/PrivateRoute'

import { useRole } from '../../../hooks/authentication/useRole'
import useUser from '../../../hooks/authentication/useUser'
import { useGlobalState } from '../../../hooks/store/useGlobalState'
import useFhirResource from '../../../hooks/admin/useFhirResource'
import { getUrlParameter, scrollToTop } from '../../../utils/helpers'

import '../../../App.scss'
import Encounters from '../../../components/FhirData/Encounters'

interface RouteParams {
  page: string | undefined;
  detailId: string | undefined;
}

const getKeys = () => {
  const memberKeys = [
    { label: 'Member Profile', resource: 'Patient', show: true },
    { label: 'Coverage', resource: 'Coverage', show: true },
    { label: 'EOBs', resource: 'ExplanationOfBenefit', show: true },
  ]
  const clinicalKeys = appConfig.clinical_fhir_data.filter((data) => data.show)
  const allKeys = [...memberKeys, ...clinicalKeys]

  const keyResourceMap = allKeys.reduce((acc, { label, resource }) => {
    acc[label] = resource
    return acc
  }, {} as Record<string, string>)

  return { allKeys, keyResourceMap }
}

const Member: FC = () => {
  const fhirId = useGlobalState((state) => state.fhirId)

  const { allKeys: keys, keyResourceMap } = getKeys()
  const [key, setKey] = useState('Member Profile')

  const { page, detailId } = useParams<RouteParams>()
  const history = useHistory()

  const [eobPageState, setEobPageState] = useState({
    getEobPages: '',
    getEobPagesOffset: '',
    eobPageId: ''
  })

  const { data: eobData, refetch: getPatientEobs, isStale: eobsIsStale, isFetching: fetchingEobs } = useFhirResource(fhirId, 'ExplanationOfBenefit', 15, eobPageState.getEobPages, eobPageState.getEobPagesOffset, eobPageState.eobPageId)

  const [encounterPageState, setEncounterPageState] = useState({
    getEncounterPages: '',
    getEncounterPagesOffset: '',
    encounterPageId: ''
  })

  const { data: encounterData, refetch: getPatientEncounters, isStale: encountersIsStale, isFetching: fetchingEncounters } = useFhirResource(fhirId, 'Encounter', 25, encounterPageState.getEncounterPages, encounterPageState.getEncounterPagesOffset, encounterPageState.encounterPageId)

  const fhirResourcesConfig = [
    { key: 'patientData', resourceType: 'Patient' },
    { key: 'coverageData', resourceType: 'Coverage', count: 25 },
    { key: 'conditionData', resourceType: 'Condition', count: 100 },
    { key: 'immunizationData', resourceType: 'Immunization', count: 25 },
    { key: 'observationData', resourceType: 'Observation', count: 25 },
    { key: 'allergyData', resourceType: 'AllergyIntolerance', count: 25 },
    { key: 'medications', resourceType: 'MedicationRequest', count: 25 },
    { key: 'procedures', resourceType: 'Procedure', count: 25 },
    { key: 'encounterData', resourceType: 'Encounter', count: 25 },
  ]

  const fhirResources = fhirResourcesConfig.map(({ key, resourceType, count }) => {
    const { data, refetch, isStale, isFetching, isLoading } = useFhirResource(fhirId, resourceType, count)
    return { key, data, refetch, isStale, isFetching, isLoading }
  })

  const {
    patientData,
    coverageData,
    conditionData,
    immunizationData,
    observationData,
    allergyData,
    medications,
    procedures,
  } = fhirResources.reduce((acc, { key, data, refetch, isStale, isFetching, isLoading }) => {
    acc[key] = { data, refetch, isStale, isFetching, isLoading }
    return acc
  }, {} as Record<string, any>)

  const handleChangeEobPage = (url: string) => {
    setEobPageState({
      getEobPages: getUrlParameter('_getpages', url),
      getEobPagesOffset: getUrlParameter('_getpagesoffset', url),
      eobPageId: getUrlParameter('_pageId', url)
    })
  }

  const handleChangeEncounterPage = (url: string) => {
    setEncounterPageState({
      getEncounterPages: getUrlParameter('_getpages', url),
      getEncounterPagesOffset: getUrlParameter('_getpagesoffset', url),
      encounterPageId: getUrlParameter('_pageId', url)
    })
  }

  const [carePlanPageState, setCarePlanPageState] = useState({
    getCarePlanPages: '',
    getCarePlanPagesOffset: '',
    carePlanPageId: ''
  })

  const handleChangeCarePlanPage = (url: string) => {
    setCarePlanPageState({
      getCarePlanPages: getUrlParameter('_getpages', url),
      getCarePlanPagesOffset: getUrlParameter('_getpagesoffset', url),
      carePlanPageId: getUrlParameter('_pageId', url)
    })
  }

  const { data: carePlanData, refetch: getCarePlans, isStale: carePlansIsStale, isFetching: fetchingCarePlans } = useFhirResource(fhirId, 'CarePlan', 25, carePlanPageState.getCarePlanPages, carePlanPageState.getCarePlanPagesOffset, carePlanPageState.carePlanPageId)

  const { role } = useRole()
  const { data: user } = useUser()

  useEffect(() => {
    patientData.refetch()
    coverageData.refetch()
  }, [])

  useEffect(() => {
    if (key === 'AllergyIntolerance' && allergyData.isStale) {
      allergyData.refetch()
    }

    if (key === 'Condition' && conditionData.isStale) {
      conditionData.refetch()
    }

    if (key === 'ExplanationOfBenefit' && eobsIsStale) {
      getPatientEobs()
    }

    if (key === 'Immunization' && immunizationData.isStale) {
      immunizationData.refetch()
    }

    if (key === 'Observation' && observationData.isStale) {
      observationData.refetch()
    }

    if (key === 'MedicationRequest' && medications.isStale) {
      medications.refetch()
    }

    if (key === 'Procedure' && procedures.isStale) {
      procedures.refetch()
    }

    if (key === 'Encounter' && encountersIsStale) {
      getPatientEncounters()
    }

    if (key === 'CarePlan' && carePlansIsStale) {
      getCarePlans()
    }

  }, [key, patientData.isStale, eobsIsStale, coverageData.isStale, conditionData.isStale, detailId, allergyData.isStale, procedures.isStale, medications.isStale, immunizationData.isStale, observationData.isStale, encountersIsStale, carePlansIsStale])

  useEffect(() => {
    if (page) {
      const key = keys.find((k) => k.resource === page)
      if (key) {
        setKey(key.resource)
      } else {
        history.replace('/404')
      }
    } else {
      setKey('Patient')
    }
    scrollToTop()
  }, [page])

  useEffect(() => {
    if (detailId) {
      if (key === 'Coverage') {
        history.replace('/404')
      }

      scrollToTop()
    }
  }, [key, detailId])

  const handleChangeKey = (k: string | null) => {
    const key = k || ''
    setKey(key)

    if (key) {
      const path = key === 'Patient' ? '/patient' : `/patient/${key}`
      history.push(path)
    }

    scrollToTop()
  }

  useEffect(() => {
    if (role === 'member') {
      if (fhirId !== user.memberId) {
        history.replace('/404')
      }
    }
  }, [fhirId, role])

  const renderFhirDataResource = (resource: string) => {
    switch (resource) {
      case 'Patient':
        return (
          <MemberProfile
            patientData={patientData?.data}
            loading={patientData?.isLoading || coverageData?.isLoading}
            coverageData={coverageData?.data}
          />
        )
      case 'Coverage':
        return (
          <Coverage
            patientData={patientData?.data}
            coverageData={coverageData?.data}
            isFetching={coverageData?.isFetching}
          />
        )
      case 'ExplanationOfBenefit':
        return (
          <Eobs
            patientData={patientData?.data}
            eobData={eobData}
            eobDetailId={detailId}
            page={page}
            isFetching={fetchingEobs}
            handleChangePage={handleChangeEobPage}
          />
        )
      case 'AllergyIntolerance':
        return (
          <Allergies
            patientData={patientData?.data}
            allergyData={allergyData?.data}
            isFetching={allergyData?.isFetching}
            allergyDetailId={detailId}
            page={page}
          />
        )
      case 'Condition':
        return (
          <Conditions
            conditionData={conditionData?.data}
            isFetching={conditionData?.isFetching}
            patientData={patientData?.data}
            conditionDetailId={detailId}
            page={page}
          />
        )
      case 'Immunization':
        return (
          <Immunizations
            patientData={patientData?.data}
            immunizationData={immunizationData?.data}
            immunizationDetailId={detailId}
            page={page}
            isFetching={immunizationData?.isFetching}
          />
        )
      case 'Observation':
        return (
          <Observations
            patientData={patientData?.data}
            observationData={observationData?.data}
            observationDetailId={detailId}
            page={page}
            isFetching={observationData?.isFetching}
          />
        )
      case 'MedicationRequest':
        return (
          <Medications
            patientData={patientData?.data}
            page={page}
            isFetching={medications?.isFetching}
            medicationData={medications?.data}
            medicationDetailId={detailId}
          />
        )
      case 'Procedure':
        return (
          <Procedure
            patientData={patientData?.data}
            page={page}
            isFetching={procedures?.isFetching}
            procedureData={procedures?.data}
            procedureDetailId={detailId}
          />
        )
      case 'Encounter':
        return (
          <Encounters
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingEncounters}
            encounterData={encounterData}
            encounterDetailId={detailId}
            handleChangePage={handleChangeEncounterPage}
          />
        )
      case 'CarePlan':
        return (
          <CarePlans
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingCarePlans}
            carePlanData={carePlanData}
            carePlanDetailId={detailId}
            handleChangePage={handleChangeCarePlanPage}
          />
        )
      default:
        return <div>Not a supported Portal Resource</div>
    }
  }

  return (
    <main className='fhirData'>
      <Container>
        <Tab.Container activeKey={key} onSelect={handleChangeKey} transition={false}>
          <h2 className='headerText'>Member FHIR Data</h2>
          <Row>
            <Col sm={2}>
              <Nav variant='pills' className='flex-column'>
                {
                  keys.map((key) => (
                    <Nav.Item className='patient-pill' key={`nav-item-${key.resource}`}>
                      <Nav.Link eventKey={key.resource}>{key.label}</Nav.Link>
                    </Nav.Item>
                  ))
                }
              </Nav>
            </Col>
            <Col sm={10} className='mt-sm-0 mt-3'>
              <Tab.Content>
                {
                  keys.map((key) => (
                    <Tab.Pane eventKey={key.resource} key={`tab-pane-${key.resource}`}>
                      {
                        renderFhirDataResource(key.resource)
                      }
                    </Tab.Pane>
                  ))
                }
              </Tab.Content >
            </Col >
          </Row >
        </Tab.Container >
      </Container >
    </main >
  )
}

export default isAuthenticated(Member)
