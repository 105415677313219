import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner, Card } from 'react-bootstrap'
import { formatDateInTimeZone } from '../../../../utils/helpers'
import { handleCodeableConcept } from '../../../../utils/helpers'
import { useHistory } from 'react-router-dom'

interface Props {
    allergyDetailId: any;
    allergyData: any;
    isFetching: boolean;
}

const AllergyDetail: FC<Props> = ({ allergyDetailId, allergyData, isFetching }: Props) => {
    const [allergy, setAllergy] = useState<any>(null)

    const history = useHistory()

    useEffect(() => {
        if (!allergyData) return

        const foundAllergy = allergyData.entry.find((a: any) => a.resource.id === allergyDetailId)
        if (foundAllergy) {
            setAllergy(foundAllergy)
        } else {
            history.push('/404')
        }
    }, [allergyData, allergyDetailId, history])

    return (
        isFetching ? (
            <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                    <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    />
                    Loading Member Allergies...
                </td>
            </tr>
        ) : allergy && allergyData.entry ? (
            <>
                <dl className='dataContainer'>
                    <Row>
                        <Col sm={3}>
                            <dt>Type</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.type?.charAt(0)?.toUpperCase() + allergy?.resource?.type?.slice(1) || ''}
                        </Col>
                        <Col sm={3}>
                            <dt>Code</dt>
                        </Col>
                        <Col sm={9}>
                            {handleCodeableConcept(allergy?.resource?.code)}
                        </Col>
                        <Col sm={3}>
                            <dt>Recorded Date</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.recordedDate ? formatDateInTimeZone(allergy?.resource?.recordedDate, 'MM/dd/yyyy', 'UTC') : ''}
                        </Col>
                        <Col sm={3}>
                            <dt>ID</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.id || ''}
                        </Col>

                        {/* everything else */}

                        <Col sm={3}>
                            <dt>Category</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.category?.[0] || ''}
                        </Col>
                        <Col sm={3}>
                            <dt>Criticality</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.criticality || ''}
                        </Col>
                        {/* <Col sm={3}>
                        <dt>Allergin</dt>
                    </Col>
                    <Col sm={9}>
                        {allergy?.resource?.coding?.[0]?.display || ''}
                    </Col> */}
                        <Col sm={3}>
                            <dt>Asserter</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.asserter?.reference || ''}
                        </Col>
                        <Col sm={3}>
                            <dt>Note</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.note?.[0] || ''}
                        </Col>

                        {/* <Col sm={3}>
                        <dt>Onset</dt>
                    </Col>
                    <Col sm={9}>
                        {allergy?.resource?.Onset || ''}
                    </Col> */}
                        <Col sm={3}>
                            <dt>Last Occurence</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.lastOccurance || ''}
                        </Col>

                        <Col sm={3}>
                            <dt>Clinical Status</dt>
                        </Col>
                        <Col sm={9}>
                            {handleCodeableConcept(allergy?.resource?.clinicalStatus)}
                        </Col>
                        <Col sm={3}>
                            <dt>Verification Status</dt>
                        </Col>
                        <Col sm={9}>
                            {handleCodeableConcept(allergy?.resource?.clinicalStatus)}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <dt>Reactions</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.reaction?.map((item: any, index: number) => (
                                <Card key={index} className='mt-3 w-100'>
                                    <Card.Body>

                                        <Row>
                                            <Col xs={6}>
                                                Substance:
                                            </Col>
                                            <Col xs={6}>
                                                {handleCodeableConcept(item?.substance)}
                                            </Col>
                                            <Col xs={6}>
                                                Reaction:
                                            </Col>
                                            <Col xs={6}>
                                                {handleCodeableConcept(item?.manifestation?.[0])}
                                            </Col>
                                            <Col xs={6}>
                                                Description:
                                            </Col>
                                            <Col xs={6}>
                                                {item?.description}
                                            </Col>
                                            <Col xs={6}>
                                                Onset:
                                            </Col>
                                            <Col xs={6}>
                                                {item?.onset}
                                            </Col>
                                            <Col xs={6}>
                                                Severity:
                                            </Col>
                                            <Col xs={6}>
                                                {item.severity}
                                            </Col>
                                            <Col xs={6}>
                                                Exposure Route:
                                            </Col>
                                            <Col xs={6}>
                                                {handleCodeableConcept(item?.exposureRoute)}
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            )) || 'No Reactions'}
                        </Col>
                    </Row>
                </dl >
            </>

        ) :
            <tr>
                <td colSpan={6} rowSpan={15} className='text-center'>
                    No Allergy Details found for this member.
                </td>
            </tr>
    )
}

export default AllergyDetail