import { capitalizeFirstLetter, formatDateInTimeZone } from '../helpers'
import PatientName from '../../types/PatientName'
import PatientTelecom from '../../types/PatientTelecom'
import { sortCoveragesByStartDate } from './coverage'
import { Address } from '../../types/FHIRTypes/Address'

export function buildPatientName(patientNames: Array<PatientName>): string {
  if (patientNames) {
    const officialName = patientNames.find((patientName: PatientName) => patientName.use === 'official')
    let familyName
    let givenName
    if (officialName) {
      familyName = officialName?.family || ''
      givenName = officialName?.given.join(' ')
    } else {
      const { family, given } = patientNames[0]
      familyName = family || ''
      givenName = given.join(' ')
    }
    return `${givenName} ${familyName}`
  }
  return ''
}

export function buildPatientGeneralPractitioner(patientPractitioner: any): string {
  return patientPractitioner && patientPractitioner[0].reference ? patientPractitioner[0].reference : ''
}


export function buildPatientAddress(patientAddresses: Array<Address>): Array<{ address: string; type: string; }> {
  if (!patientAddresses) return []

  const address = patientAddresses.map((patientAddresses) => {
    return { address: patientAddresses.text || '', type: patientAddresses.type || '' }

  })
  return address
}

export function buildPatientBirthday(resource: any): string {
  return resource && resource.birthDate ? formatDateInTimeZone(resource.birthDate, 'MM/dd/yyyy', 'UTC') : ''
}

export function buildPatientTelecom(patientTelecoms: Array<PatientTelecom>, use: string): Array<string | undefined> {
  if (!patientTelecoms) return []
  //use is type of communication (phone/email)
  const telecoms = patientTelecoms.filter((patientTelecom) => patientTelecom.system === use)

  return telecoms.map((patientTelecom) => {
    if (patientTelecom.value) {
      //basic check to see if email was listed as a phone number resource
      // if (patientTelecom.system === 'phone' && patientTelecom.value.includes('@')) {
      //   return ''
      // }
      //get rid of semicolon in email
      return patientTelecom.system === 'phone'
        ? `${capitalizeFirstLetter(patientTelecom.use)}: ${patientTelecom.value}`
        : `${capitalizeFirstLetter(patientTelecom.use)} ${patientTelecom.value}`
    }
  })
}
function validatePhoneNumber(value: string): boolean {



  return (true)
}

export function buildPatientContact(patientTelecom: Array<PatientTelecom>): Array<string | undefined> {
  if (!patientTelecom) return []
  return patientTelecom.map((telecom) => {
    if (telecom.value) {
      return ` ${(telecom.system)}: ${(telecom.use)}: ${telecom.value}`
    }
    return undefined
  })
}

export function patientHasEmail(patientTelecoms: Array<PatientTelecom>): boolean {
  let hasEmail = false
  if (!patientTelecoms) return hasEmail
  const email = patientTelecoms.find((patientTelecom) => patientTelecom.system === 'email')
  if (email) {
    hasEmail = true
  }
  return hasEmail
}

export function buildPatientIdUI(resource: any): string {
  let idSplits = null
  if (resource) {
    idSplits = resource.identifier[0].value.split('-')
    for (let i = 2; i < idSplits.length; i++) {
      idSplits[1] += '-' + idSplits[i]
    }
  }
  return idSplits ? idSplits[1] : 'N/A'
}

export function buildPatientId(resource: any): string {
  return resource ? resource.identifier[0].value : 'N/A'
}

export function buildPatientStatus(resource: any): string {
  const patientActive = resource ? resource.active : null
  return patientActive ? 'Yes' : 'No'
}

export function buildPatientGender(resource: any): string {
  const patientGender = resource ? resource.gender : null
  return patientGender ? capitalizeFirstLetter(patientGender) : ''
}



export const buildPatientMemberId = (patient: any, coverages: any, isSingleTenantServer: any): string => {
  if (isSingleTenantServer) {
    return patient.identifier?.[0]?.value || ''
  }

  const patientCoverages = coverages?.entry?.map((entry: any) => entry.resource) || []
  const sortedCoverages = sortCoveragesByStartDate(patientCoverages)

  if (sortedCoverages.length === 0) {
    return ''
  }

  const mostRecentCoverage = sortedCoverages[0]
  const patientMemberId = mostRecentCoverage.identifier?.find((id: any) => id.type?.coding?.[0]?.code === 'MB')

  return patientMemberId?.value || ''
}
