import React, { FC } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { handleCodeableConcept } from '../../../utils/helpers'
import useFhirResource from '../../../hooks/admin/useFhirResource'
import { formatDateWithFormat } from '../../../utils/helpers'
import useEnvironment from '../../../hooks/location/useEnviroment'

interface Props {
    resourceData: any;
    loading: boolean;
}

const MetaDataFooter: FC<Props> = ({ resourceData, loading }: Props) => {
    const { data: environmentData } = useEnvironment()
    const smileTenantValue = environmentData?.smileTenant?.toUpperCase() === 'CIGNA' ? '/' : `${environmentData?.smileTenant}/`
    const smileURLValue = environmentData?.smileURL

    return (
        <>
            <h4>FHIR Resource Information</h4>
            <section>
                {
                    loading ? (
                        <div className='d-flex dataContainer'>
                            <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                            />
                            <span style={{ marginLeft: '10px' }}>Loading Member Profile...</span>
                        </div>
                    ) : resourceData ? (
                        <dl className='dataContainer'>
                            <Row>
                                <Col sm={3}>
                                    <dt>Resource ID</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resourceData.id}</dd>
                                </Col>
                                <Col sm={3}>
                                    <dt>Resource Identifier</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        {resourceData.identifier && Array.isArray(resourceData.identifier) ? (
                                            resourceData.identifier.map((identifier: any, index: number) => (
                                                <div key={`resource-identifier-${index}`}>{identifier.value}</div>
                                            ))
                                        ) : null}
                                    </dd>
                                </Col>
                                <Col sm={3}>
                                    <dt>Resource Last Updated</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resourceData?.meta?.lastUpdated ? formatDateWithFormat(resourceData.meta.lastUpdated, 'MM/dd/yyyy HH:mm:ss') : ''}</dd>
                                </Col>
                                {/* <Col sm={3}>
                                    <dt>Resource URL</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{smileURLValue}{smileTenantValue}{resourceData?.id}</dd>
                                </Col> */}
                            </Row>
                        </dl>
                    ) :
                        <div>
                            <div className='text-center'>
                                Profile not found.
                            </div>
                        </div>

                }
            </section>
        </>
    )
}

export default MetaDataFooter
