import React, { FC, useEffect } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { buildPatientMemberId, buildPatientName, buildPatientGender, buildPatientBirthday, buildPatientAddress, buildPatientTelecom, patientHasEmail, buildPatientContact, buildPatientGeneralPractitioner } from '../../../utils/fhirUtils/patient'
import { capitalizeFirstLetter } from '../../../utils/helpers'
import useEnvironment from '../../../hooks/location/useEnviroment'
import useFhirResource from '../../../hooks/admin/useFhirResource'
import ProvenanceDetail from '../Provenance'
import { handleCodeableConcept } from '../../../utils/helpers'
import MetaDataFooter from '../MetaDataFooter'

interface Props {
  patientData: any;
  loading: boolean;
  coverageData: any
}

const MemberProfile: FC<Props> = ({ patientData, loading, coverageData }: Props) => {
  const patientAddress = patientData ? patientData.address : []
  const patientTelecom = patientData ? patientData.telecom : []
  const patientCommunication = patientData ? patientData.communication : []
  const patientExtension = patientData ? patientData.extension : []
  const patientAddressData = buildPatientAddress(patientAddress)
  const patientPhoneData = buildPatientContact(patientTelecom)
  const patientEmailData = buildPatientTelecom(patientTelecom, 'email')

  const { data: environmentData } = useEnvironment()
  const { data: provenanceData, refetch: getProvenance, isFetching: fetchingProvenance } = useFhirResource(patientData?.id, 'Provenance', 999)
  useEffect(() => {
    if (patientData?.id) {
      getProvenance()
    }
  }, [patientData?.id])

  const provenanceEntries = provenanceData?.entry || []
  const patientProvenance = provenanceEntries.find((prov: any) => prov.resource.id === `PROV-PATIENT-${patientData?.id}`) || ''
  return (
    <>
      <h3>Member Profile</h3>
      <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
      <section>
        {
          loading ? (
            <div className='d-flex dataContainer'>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              <span style={{ marginLeft: '10px' }}>Loading Member Profile...</span>
            </div>
          ) : patientData && Object.keys(patientData).length > 0 ? (
            <dl className='dataContainer'>
              <Row>
                <Col sm={3}>
                  <dt>Most Recent Member ID</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildPatientMemberId(patientData, coverageData, environmentData?.isSingleTenantServer)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Name</dt>
                </Col>
                <Col sm={9}>
                  <dd>{patientData ? buildPatientName(patientData.name) : ''}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Gender</dt>
                </Col>
                <Col sm={9}>
                  <dd>{patientData?.gender}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Birth Date</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildPatientBirthday(patientData)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Address(s)</dt>
                </Col>
                <Col sm={9}>
                  {patientAddressData.map((patientAddress) => (
                    <dd key={patientAddress.address}>
                      {patientAddress.type && `${patientAddress.address} Type:`} {capitalizeFirstLetter(patientAddress.type)}
                    </dd>
                  ))}
                </Col>

                <Col sm={3}>
                  <dt>Communication</dt>
                </Col>
                <Col sm={9}>
                  {patientCommunication?.map((patientLanguage: any) => (
                    <dd key={patientLanguage?.language}>
                      {handleCodeableConcept(patientLanguage?.language)}
                    </dd>
                  ))}
                </Col>
                <Col sm={3}>
                  <dt>Contact</dt>
                </Col>
                <Col sm={9}>
                  {patientPhoneData.map((patientTelecom, index) => (
                    <dd key={index}>
                      {patientTelecom}
                    </dd>
                  ))}
                </Col>

                <Col sm={3}>
                  <dt>Race</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {patientExtension?.[0]?.extension?.[1]?.valueString}
                  </dd>
                </Col>

                <Col sm={3}>
                  <dt>Ethnicity</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {patientExtension?.[1]?.extension?.[1]?.valueString}
                  </dd>
                </Col>

                <Col sm={3}>
                  <dt>Birth Sex</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {patientData.birthsex ? patientData.birthsex : ''}
                  </dd>
                </Col>


                {/* <Col sm={3}>
                  <dt>General Practitioner</dt>
                </Col>
                <Col sm={9}>
                  <dd>{
                    buildPatientGeneralPractitioner(patientData.generalPractitioner)
                  }</dd>
                </Col>
                <Col sm={3}>
                  <dt>Resource URL</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    https://18.233.54.217:9090/fhir/Patient/{buildPatientId(patientData)}
                  </dd>
                </Col> */}
              </Row>
            </dl>
          ) :
            <div>
              <div className='text-center'>
                Profile not found.
              </div>
            </div>
        }
      </section>
      <MetaDataFooter resourceData={patientData} loading={loading} />
      {provenanceData && (
        <ProvenanceDetail provenanceEntries={[patientProvenance?.resource]} isFetching={fetchingProvenance} />
      )}
    </>
  )
}

export default MemberProfile